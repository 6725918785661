"use client"

import { Button } from '@/components/ui/button';
import { useRouter} from 'next/navigation';
import CircleGrid from './circle-grid';
import { SignInButton, SignUpButton } from '@clerk/nextjs';

export default function Home() {
  
  const { push } = useRouter();

  const clickLoginHandler = () => {
    push('/api/auth/login');
  }

  const clickRegisterHandler = () => {
    push('/api/auth/register');
  }

  return (
    <main className="flex min-h-screen flex-col pt-4 px-8 justify-center items-center">
      <div className="absolute inset-0 z-0">
        <CircleGrid />
      </div >
        <div className="space-y-2 px-4 flex flex-col w-96 z-10">
          <div className='text-center'>
            <h1 className='text-lg font-bold tracking-tight'>Melrose</h1>
          </div>

          <SignInButton
            mode="modal"
          >
            <Button>Sign In</Button>
          </SignInButton>
          
          <SignUpButton
            mode="modal"
          >
            <Button>Sign Up</Button>
          </SignUpButton>
        </div>
    </main>
  )
}


