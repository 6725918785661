// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers/

import React, { useState, useEffect } from "react"
//import styled from "styled-components"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
export default function CircleGrid() {
    const numberOfCircles = 120 // 10 x 10 grid
    const coloredCircles = 5 // Number of colored circles

    // Initialize circles with random opacity
    const initialCircles = Array.from({ length: numberOfCircles }, (_, i) => ({
        id: i,
        color: Math.random() < 0.1 ? "#adfa1d" : "white", // replace 'red' with your color
        opacity: Math.random() < 0.1 ? Math.random() : 0, // 90% chance to be invisible
    }))

    const [circles, setCircles] = useState(initialCircles)

    useEffect(() => {
        const interval = setInterval(() => {
            setCircles((prevCircles) => {
                const currentlyVisible = prevCircles.filter(
                    (c) => c.opacity > 0
                )
                const currentlyHidden = prevCircles.filter(
                    (c) => c.opacity === 0
                )

                // Randomly hide some of the visible circles
                const toHide = currentlyVisible
                    .sort(() => 0.5 - Math.random())
                    .slice(0, 5)

                // Randomly show some of the hidden circles
                const toShow = currentlyHidden
                    .sort(() => 0.5 - Math.random())
                    .slice(0, 5)

                return prevCircles.map((circle) => {
                    if (toHide.some((c) => c.id === circle.id)) {
                        return { ...circle, opacity: 0 }
                    } else if (toShow.some((c) => c.id === circle.id)) {
                        return { ...circle, opacity: 1 }
                    }
                    return circle
                })
            })
        }, 500)

        return () => clearInterval(interval)
    }, [])

    return (
        <Grid>
            {circles.map((circle) => (
                <Circle
                    key={circle.id}
                    color={circle.color}
                    opacity={circle.opacity}
                />
            ))}
        </Grid>
    )
}

const Grid = ({ children }: { children: React.ReactNode }) => (
  <div className="grid grid-cols-8 gap-4">
    {children}
  </div>
)

const Circle = ({ color = "white", opacity } : { color? : string, opacity: number}) => (
  <div
    className={`w-1 h-1 rounded-full bg-white transition ease-in-out shadow-2xl shadow-blue-500/50 opacity-${opacity} mt-10`}
    style={{ 
      borderRadius: '50%', 
      backgroundColor: color, 
      boxShadow: `0px 0px 5px ${color}`,
      transition: 'opacity ease-in-out 2s', 
      opacity: opacity 
    }}
  ></div>
)

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style
const containerClasses = "h-full flex justify-center items-center overflow-hidden"
